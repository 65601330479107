import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import SignaturePad from 'signature_pad';
import Swal from 'sweetalert2';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import { ListarEmpresasService } from 'src/app/shared/services/listar-empresas.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-firmacalidad',
  templateUrl: './firmacalidad.component.html',
  styleUrls: ['./firmacalidad.component.scss']
})
export class FirmacalidadComponent implements OnInit {

  @ViewChild('firmaDigital', { static: true }) signaturePadElement: any;

  Cargando: string;
  signaturePad: any;
  datos_get: any;
  IdEmpresa: string;
  IdSucursal: string;

  UrlFirma: string;
  IdUsuario: string;

  FromTrabajos: FormGroup;

  constructor(public bsModalRef: BsModalRef, public options: ModalOptions,
    public pedidosServices: PedidosService,
    private listarempresasservice: ListarEmpresasService, private fb: FormBuilder
  ) {

    this.Cargando = "none"
    this.datos_get = this.options.initialState;
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.IdUsuario = JSON.parse(sessionStorage.getItem('idtblTercero'));

    this.FromTrabajos = new FormGroup({
      procesos: this.fb.array([])
    })

    this.buildForm();

  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      backgroundColor: "rgb(255, 255, 255)"
    });
  }

  async buildForm() {
    const controlArray = this.FromTrabajos.get("procesos") as FormArray;
    Object.keys(this.datos_get).forEach(async (i) => {
      controlArray.push(
        this.fb.group({
          Caja: new FormControl(this.datos_get[i].Caja),
          Odontologo: new FormControl(this.datos_get[i].Odontologo),
          Paciente: new FormControl(this.datos_get[i].Paciente),
          Trabajo: new FormControl(this.datos_get[i].Trabajo),
          Proceso: new FormControl(this.datos_get[i].Proceso),
          IdHistorial: new FormControl(this.datos_get[i].idtblDtllHistorico),
          idtblDtllPedidos: new FormControl(this.datos_get[i].idtblDtllPedidos),
          IdUsuario: new FormControl(this.IdUsuario),
          UrlFima: new FormControl(),
          ObserCalidad: new FormControl(' '),
          Resultado: new FormControl(' ')
        })
      )
    })
  }

  ngOnInit(): void {
  }

  Cancelaringreso() {
    this.bsModalRef.hide();
  }

  URLtoBlob(dataURL: any) {
    const partes = dataURL.split(';base64,');
    const contentType = partes[0].split(':')[1];
    const raw = window.atob(partes[1]);
    const rawL = raw.length;
    const array = new Uint8Array(rawL);
    for (let i = 0; i < rawL; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return new Blob([array], { type: contentType })
  }

  async guardarProceso() {
    try {

      if (this.signaturePad.isEmpty()) {
        Swal.fire({
          title: "La firma del documento es obligatorio segun Res. 214 de 15-02-2022 del MinSalud.",
          icon: 'warning'
        });
      }
      else {

        this.Cargando = 'block';

        const controlArray = this.FromTrabajos.get('procesos') as FormArray;

        const blob = this.URLtoBlob(this.signaturePad.toDataURL('image/jpeg', 0.5));
        const bodyfirma = new FormData();
        bodyfirma.append('imagen', blob);
        bodyfirma.append('NameBucket', this.IdEmpresa + '/Firmas');
        bodyfirma.append('extArchivo', 'jpg');
        bodyfirma.append('typeArchivo', 'image/jpeg');

        await this.listarempresasservice.Cargar_Firma_Calidad_S3(bodyfirma).then(async res => {
          this.UrlFirma = res.Cargar_Firma_Calidad_S3
        })

        for (let i = 0; i < controlArray.length; i++) {
          controlArray.controls[i].get("UrlFima").setValue(this.UrlFirma)
        }

        await this.pedidosServices.UpdateFirmaCalidad(this.FromTrabajos.value).then(async resp => {
          if (this.datos_get.length != 0) {
            let resp: any
            for (let i in this.datos_get) {
              await this.pedidosServices.UpdateDllRealiza({
                iDDtllhistorico: this.datos_get[i].idtblDtllHistorico
              }).then(async (res) => {
                if (res) {
                  resp = 1
                } else {
                  resp = 0
                }
              })
            }
            if (resp == 1) {
              this.Cargando = 'none';
              Swal.fire({
                title: "Proceso completado correctamente",
                icon: 'success'
              });

              this.bsModalRef.hide()

            } else {
              this.Cargando = 'none';
              Swal.fire({
                title: "No ha realizado ninguna accion..!!",
                text: "¡Por favor comunicarce con el administrador del sistema!",
                icon: 'warning'
              });

              this.bsModalRef.hide()

            }
          }
        })
      }

    } catch (error) {
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }




  }

  chkResultado(event: any) {

    this.Cargando = "Block"
    const controlArray = this.FromTrabajos.get('procesos') as FormArray;

    for (let i = 0; i < controlArray.length; i++) {
      controlArray.controls[i].get("Resultado").setValue(event.target.value)
    }
    this.Cargando = "none"
  }

  txtObserva(event: any) {
    this.Cargando = "Block"
    const controlArray = this.FromTrabajos.get('procesos') as FormArray;

    for (let i = 0; i < controlArray.length; i++) {
      controlArray.controls[i].get("ObserCalidad").setValue(event.target.value)
    }
    this.Cargando = "none"
  }



}
