import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ListarTercero, ListarTerceroRoot } from '../../../shared/models/terceros';
import { ToastrService } from 'ngx-toastr';
import { TercerosService } from 'src/app/shared/services/terceros.service';
import { ListaPrecioAlineadoreRoot, ListaPrecioAlineadore, PrecioIngresos } from '../../../shared/models/pedidos';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PedidosService } from '../../../shared/services/pedidos.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-ing-tratamiento',
  templateUrl: './ing-tratamiento.component.html',
  styleUrls: ['./ing-tratamiento.component.scss']
})
export class IngTratamientoComponent implements OnInit {

  validationForm: FormGroup;
  formPrecios: FormGroup;
  modelFooter: NgbDateStruct;
  arrUsuarios: ListarTercero[];
  IdEmpresa: string;
  IdSucursal: string;
  boton: any;
  idTrabajo: any;
  idtblTercero: string;
  arrPrecios: ListaPrecioAlineadore[];
  arrIngreso: PrecioIngresos[];
  precio: string;

  constructor(private fb: FormBuilder,
    private _TerceroService: TercerosService,
    private toastaService: ToastrService,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    public pedidosServices: PedidosService) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.idtblTercero = JSON.parse(sessionStorage.getItem('idtblTercero'));
    this.idTrabajo = this.options.initialState;

    this.arrIngreso = [];
    this.precio = "";

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
  

    this.validationForm = this.fb.group({
      typePlaca: ['', [Validators.required]],
      marcaPlaca: ['', [Validators.required]],
      numPlacas: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(3)]],
      descripcion: ['', Validators.required],

      IdProceso: ['5645'],
      IdUsuario: [this.idtblTercero],
      observacion: [' ', Validators.required],
      Fk_UsuarioRealiza: ['', Validators.required],
      FechaEntrada: [formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")],
      fechaentrega: ['', Validators.required],
      IdDtalle: [this.idTrabajo.idtblDtllPedidos],
      idtblDtllHistorico: [this.idTrabajo.idtblDtllHistorico],
      idtblhistoricoprecio: [this.idTrabajo.idtblhistoricoprecio],
      Preciouni: [[]]

    })

    this.formPrecios = this.fb.group({
      IdPrecio: ['', Validators.required],
      Cantidad: ['', Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(3)],
      Preciouni: ['', Validators.required],
      Nombre: [''],
      Nota: ['', Validators.required]
    })

  }

  async ngOnInit() {
    try {
      this.boton = false;
      const resp: ListarTerceroRoot = await this._TerceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })
      this.arrUsuarios = resp.ListarTerceros;


      const respu: ListaPrecioAlineadoreRoot = await this.pedidosServices.getPrecioAlinieadores({
        id: this.idTrabajo.IdClasAlineadores
      })
      this.arrPrecios = respu.ListaPrecioAlineadores;


    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async guardarTratamiento(datos: any) {
    this.validationForm.value.Preciouni = this.arrIngreso
    this.validationForm.value.fechaentrega = formatDate(this.validationForm.value.fechaentrega, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
    this.boton = true
    await this.pedidosServices.IngresoTratamiento(this.validationForm.value).then((res) => {
      if (res) {
        this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });
        this.bsModalRef.hide();
      }
    }, error => {
      console.log(error);

      this.toastaService.warning(error.error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
      this.bsModalRef.hide();
    })

  }

  aggPrecio(form: FormGroup) {
    const nombre = this.arrPrecios.find(res => res.idtblprecioalinieadores == form.value.IdPrecio)
    form.get("Nombre").setValue(nombre.ChrNombre)
    form.value.IdPrecio = parseInt(form.value.IdPrecio)
    this.arrIngreso.push(form.value)
    this.formPrecios.reset()
  }

  onChange($event) {
    this.precio = String(this.arrPrecios[$event.target.value - 1].Valor);
  }


}
