import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ListarSeleEmpresa, ListarSeleEmpresaRoot } from '../models/listarSelecEmpresas'

@Injectable({
  providedIn: 'root'
})
export class ListarEmpresasService {

  listarEmpresas: ListarSeleEmpresaRoot
  formdata: any;
  basicHeaders: any;
  basicHeadersNoToken: any;
  token: string;
  idUsuario: string

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');
    this.idUsuario = JSON.parse(sessionStorage.getItem('idUsuario'))

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    })

    this.basicHeadersNoToken = new HttpHeaders({
      'Content-Type': 'application/json'
    })

    this.formdata = new HttpHeaders({

    })

  }

  getEntreprise(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarEmpresas', body, { headers: this.basicHeaders }).toPromise();
  }

  getAll({ id }): Promise<ListarSeleEmpresaRoot> {
    const body = { id }
    return this.http.post<ListarSeleEmpresaRoot>(Enviroment.url_backend + 'ListarSeleEmpresa', body, { headers: this.basicHeaders }).toPromise();
  }

  CreateTokenIdWeb(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'CreateIdToken', body, { headers: this.basicHeaders }).toPromise();
  }

  ValidarTokenIdWeb(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ValidarToken', body, { headers: this.basicHeaders }).toPromise();
  }

  getEmpresaNoToken(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListaEmpresaPreOrden', body, { headers: this.basicHeadersNoToken }).toPromise();
  }

  getTrabajosNoToken(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListaTrabajosPreOrden', body, { headers: this.basicHeadersNoToken }).toPromise();
  }

  getNumOrdenNoToken(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'NumPreOrden', body, { headers: this.basicHeadersNoToken }).toPromise();
  }

  Cargar_Archivos_S3(body: FormData): Promise<any> {
    return this.http.post(Enviroment.url_backend + 'CargarArchivosS3', body, { headers: this.formdata }).toPromise()
  }

  Cargar_Archivos_S3_STL(body): Promise<any> {
    return this.http.post(Enviroment.url_backend + 'CargarArchivosS3STL', body, { headers: this.basicHeaders }).toPromise()
  }

  Cargar_Firma_Calidad_S3(body: FormData): Promise<any> {
    return this.http.post(Enviroment.url_backend + 'CargarFirmaCalidadS3', body, { headers: this.formdata }).toPromise()
  }

  InsertarOrdenNoToken(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'InsertarPreOrden', body, { headers: this.basicHeadersNoToken }).toPromise();
  }

  SendEmailPreOdo(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'SendEmailPreOrdenOdo', body, { headers: this.basicHeadersNoToken }).toPromise();
  }

  SendEmailPreLab(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'SendEmailPreOrdenLab', body, { headers: this.basicHeadersNoToken }).toPromise();
  }

  SendEmailAnulado(body): Promise<any> {   
    return this.http.post<any>(Enviroment.url_backend + 'SendEmailAnulado', body, { headers: this.basicHeaders }).toPromise();
  }

  BuscasIdTerceroNoToken(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'BusTerceroID', body, { headers: this.basicHeadersNoToken }).toPromise();
  }

  Cargar_Archivos_S3_URL(UrlS3: string, body:FormData): Promise<any> {
    return this.http.put(UrlS3, body).toPromise()
  }
  
}